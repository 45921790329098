/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.6.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var TargetKind;
(function (TargetKind) {
    TargetKind["Http"] = "Http";
    TargetKind["MySql"] = "MySql";
    TargetKind["Ssh"] = "Ssh";
    TargetKind["WebAdmin"] = "WebAdmin";
})(TargetKind || (TargetKind = {}));
export function TargetKindFromJSON(json) {
    return TargetKindFromJSONTyped(json, false);
}
export function TargetKindFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TargetKindToJSON(value) {
    return value;
}
